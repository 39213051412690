import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function PrivacyPolicy(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'20px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>

          <div dir="rtl" style={{padding:'10px'}} >

              <p dir="rtl"><strong>تاريخ النفاذ: 1 يوليو 2024 م</strong></p>
              <p dir="rtl">يوضح إشعار الخصوصية هذا كيف تقوم جوول&nbsp;يُشار
                  إليها &quot; jooool&nbsp; &nbsp; &quot; &nbsp;&nbsp;أو &quot;نحن&quot;)&nbsp;بجمع واستخدام البيانات
                  الشخصية.</p>
              <p dir="rtl">تقدم&nbsp;jooool&nbsp; &nbsp; خدماتها الخاصة بطلبات النقل على منصة&nbsp;jooool
                  app&nbsp; ينطبق إشعار الخصوصية هذا على جميع مستخدمي تطبيقات الهاتف المحمول أو المواقع الإلكترونية أو
                  مراكز الاتصال&nbsp; عند طلب خدمات&nbsp;Jooool&nbsp;، وعندما يتفاعل مستخدمو طلب النقل أو يتواصلون معنا
                  فيما يتعلق بالخدمات.</p>
              <ol>
                  <li dir="rtl"><strong>الجهات المسؤولة عن البيانات</strong><strong>:</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">عند تنزيل تطبيق&nbsp;Jooool&nbsp;لتلقي وتقديم خدمات
                      النقل،&nbsp;&nbsp;ستكون&nbsp;Jooool&nbsp;&nbsp;&nbsp;&nbsp;هي المتحكم في البيانات الشخصية المقدمة
                      أثناء عملية التسجيل، والبيانات الشخصية الأخرى التي تقدمها مباشرة لحسابك الشخصي من أجل استخدام
                      تطبيقJooool .&nbsp;</li>
              </ul>
              <p dir="rtl"><br/></p>
              <ul>
                  <li dir="rtl">عند استخدام تطبيق&nbsp;Jooool&nbsp;، قد تحصل على خدمة واحدة أو أكثر. إذا كنت ترغب في
                      استخدام خدمات حجز الرحلات على وجه التحديد، فإن هذه الخدمة
                      تقدمها&nbsp;Jooool &nbsp;&nbsp;وستقوم&nbsp;بمشاركة بيانات حسابك مع&nbsp;Jooool &nbsp;&nbsp;التي
                      ستعمل كجهة تحكم منفصلة في البيانات. بالإضافة إلى ذلك، سيتم التحكم في جميع البيانات التي يتم جمعها
                      ومعالجتها واستخدامها كجزء من خدمات حجز الرحلات بواسطة&nbsp;Jooool..
                  </li>
              </ul>
              <p dir="rtl"><br/></p>
              <p dir="rtl"><br/></p>
              <ul>
                  <li dir="rtl">تتعلق إشعارات الخصوصية فقط بالبيانات التي تم استلامها وتجميعها ومعالجتها واستخدامها كجزء
                      من تسجيلك واستخدامك لخدمات حجز الرحلات المقدمة عبر&nbsp;Jooool&nbsp;سيتم التحكم في ومعالجة جميع
                      البيانات الأخرى التي يتم جمعها ومعالجتها واستخدامها للخدمات،
                  </li>
              </ul>
              <p dir="rtl"><br/></p>
              <ul>
                  <li dir="rtl">ستقوم&nbsp;Jooool&nbsp;بمشاركة بعض بياناتك المتعلقة بخدمات نقل الركاب
                      مع&nbsp;Jooool&nbsp;للأغراض الموضحة في القسم &quot; 6 &quot; وذلك بشكل أساسي لتسهيل تجربة موحدة
                      عبر الخدمات المختلفة على منصة&nbsp;Jooool &nbsp;&nbsp;كوحدة تحكم مستقلة لها.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>المدفوعات</strong><strong>:</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">لأغراض تحصيل المدفوعات المستحقة من المستخدمين إلى السائقين ، ستعمل Jooool &nbsp; وكيل
                      تحصيل وصرف للمدفوعات لرحلات&nbsp;jooool&nbsp;والمسؤول عن البيانات التي تجمعها منك مباشرة و/أو
                      تتلقاها من رحلات&nbsp;jooool .&nbsp;قد يُطلب من&nbsp;&nbsp;&nbsp;&nbsp;جوول&nbsp;&nbsp;أيضًا
                      معالجة هذه البيانات للامتثال للقوانين واللوائح المعمول بها، على الرغم من دورها كوكيل تحصيل وصرف
                      للمدفوعات لرحلات&nbsp;.
                  </li>
                  <li dir="rtl">من خلال الوصول إلى الخدمات أو استخدامها، فإنك تؤكد أنك قرأت إشعار الخصوصية هذا بالكامل
                      وتفهم أن المعلومات التي تم جمعها بواسطة&nbsp;Jooool &nbsp;&nbsp;وفقًا لإشعار الخصوصية هذا قد يتم
                      استخدامها وفقًا للقوانين المعمول بها وإشعار الخصوصية هذا وشروط الاستخدام الخاصة بالمستخدم النهائي
                      أو السائقين.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>النطاق والتطبيق :</strong></li>
              </ol>
              <p dir="rtl">ينطبق إشعار الخصوصية هذا على جميع الأفراد في أي مكان في العالم الذين يستخدمون خدماتنا أو
                  يستخدمونها. وينطبق بشكل خاص على:</p>
              <ul>
                  <li dir="rtl"><strong>السائقين</strong><strong>:</strong> الأفراد الذين يوفرون (أو يقدمون طلبًا
                      لتوفير) نقل الأشخاص، سواء بشكل فردي أو من خلال شركات النقل .
                  </li>
                  <li dir="rtl"><strong>الركاب</strong><strong>:</strong> الأفراد الذين يطلبون أو يتلقون وسائل النقل،
                      بما في ذلك أولئك الذين يتلقون وسائل النقل التي يطلبها فرد آخر.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl">التعريفات المهمة:</li>
              </ol>
              <ul>
                  <li dir="rtl"><strong>القانون الواجب التطبيق</strong> يعني أي قانون ينطبق على معالجتنا لبياناتك
                      الشخصية، سواء من خلال تقديم الخدمات لك، أو مراقبة سلوكك عبر الإنترنت (مثل استخدام ملفات تعريف
                      الارتباط)، أو استخدامك لخدماتنا أو تقديم خدماتك لنا.
                  </li>
                  <li dir="rtl"><strong>يشير المستخدم النهائي</strong> أو <strong>العميل</strong> إلى المستفيد من خدمات
                      نقل الركاب .
                  </li>
                  <li dir="rtl"><strong>البيانات الشخصية</strong> هي أي بيانات أو معلومات تتعلق بفرد ما، والتي يمكنها،
                      بمفردها أو بالاشتراك مع بيانات أو معلومات أخرى، تحديد هوية هذا الفرد.
                  </li>
                  <li dir="rtl"><strong>تعني العملية أو المعالجة</strong> جمع أو تخزين أو استخدام أو نقل البيانات
                      الشخصية.
                  </li>
                  <li dir="rtl"><strong>تعني شروط الاستخدام</strong> شروط وأحكام الركاب و/أو اتفاقية السائقين الصادرة عن
                      كيان رحلات جوول ذات الصلة من وقت لآخر والتي يقبلها الركاب والسائقين على التوالي.
                  </li>
                  <li dir="rtl"><strong>بيانات المعاملات</strong> تعني أي معلومات يتم جمعها فيما يتعلق بمعاملتك
                      مع&nbsp;Jooool &nbsp;&nbsp;والتي قد تشمل، على سبيل المثال لا الحصر، <strong>اسمك ومعلومات الاتصال
                          ومعلومات الدفع وتفاصيل المعاملة نفسها</strong><strong>.</strong></li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>جمع المعلومات</strong></li>
                  <li dir="rtl">اعتمادًا على الخدمات التي تقوم بالوصول إليها أو استخدامها، نقوم بجمع واستخدام:</li>
                  <li dir="rtl">المعلومات التي تقدمها لنا بشكل مباشر؛</li>
                  <li dir="rtl">المعلومات المتعلقة باستخدام حسابك وخدماتنا؛&nbsp;</li>
                  <li dir="rtl">المعلومات التي تم الحصول عليها من مصادر أخرى وأطراف ثالثة، والتي قد تتضمن معلومات عنك.
                  </li>
              </ol>
              <ul>
                  <li dir="rtl"><strong>المعلومات التي تقدمها لنا</strong> :&nbsp;</li>
                  <li dir="rtl">سواء كنت مستخدمًا نهائيًا أو كابتنًا، فأنت المسؤول الوحيد عن دقة المعلومات التي تقدمها
                      على منصة جوول، ولن تقوم&nbsp;Jooool &nbsp;&nbsp;بتعديل أو تحديث بياناتك الشخصية إلا بناءً على طلبك
                      أو عندما تعدل تفاصيل حسابك على منصة جوول.
                  </li>
                  <li dir="rtl">قد تتضمن البيانات الشخصية التي يتم جمعها في هذه العمليات ما يلي:</li>
                  <li dir="rtl">اسم؛</li>
                  <li dir="rtl">عنوان البريد الإلكتروني،</li>
                  <li dir="rtl">رقم التليفون؛</li>
                  <li dir="rtl">العناوين التي تقدمها إلى منصة جوول لتخزينها في ملفك الشخصي للرجوع إليها في المستقبل؛
                  </li>
                  <li dir="rtl">طريقة الدفع او السداد؛</li>
                  <li dir="rtl">عنوان السكن؛</li>
                  <li dir="rtl">معلومات الدفع،</li>
                  <li dir="rtl">الصور/الفيديو، بما في ذلك الصور أو مقاطع الفيديو المرسلة للتحقق من هويتك مثل تقنيات
                      التحقق من الوجه؛
                  </li>
                  <li dir="rtl">بطاقة الهوية (بيانات فقط ) والمعلومات المدرجة فيها، بما في ذلك على سبيل المثال لا الحصر
                      جنسك وتاريخ ميلادك وجنسيتك ومهنتك و/أو صورتك؛ و
                  </li>
                  <li dir="rtl">معلومات أخرى نطلبها بشكل معقول أو تختار تقديمها.</li>
                  <li dir="rtl">يرجى ملاحظة أنه إذا اخترت عدم تزويدنا ببعض هذه البيانات الشخصية، أو طلبت منا مسح بعضها
                      أو كلها، فقد لا نتمكن من تزويدك بخدماتنا على الإطلاق، أو تقديمها بنفس المستوى الذي يمكننا تقديمه
                      إذا كنا نستخدم تلك البيانات الشخصية.
                  </li>
                  <li dir="rtl"><strong>المعلومات التي نجمعها :</strong></li>
                  <li dir="rtl"><strong>المستخدمون النهائيون :</strong></li>
                  <li dir="rtl">عند وصولك إلى خدماتنا أو استخدامها، سواء بصفتك مستخدمًا نهائيًا أو سائقا، فإننا نجمع
                      معلومات، بعضها سيشكل بيانات شخصية. في بعض الحالات، نجمع هذه البيانات من خلال ملفات تعريف الارتباط
                      والبكسلات والعلامات وتقنيات التتبع المماثلة التي تنشئ وتحافظ على معرفات فريدة. قد تتضمن هذه
                      المعلومات:
                  </li>
                  <li dir="rtl">معلومات الشبكة المحمولة؛</li>
                  <li dir="rtl">نظام التشغيل المحمول؛</li>
                  <li dir="rtl">تقارير أعطال تطبيقات الهاتف المحمول؛</li>
                  <li dir="rtl">مميزات تطبيقات الهاتف المحمول والتطبيقات المستخدمة؛</li>
                  <li dir="rtl">إعداد المنطقة الزمنية؛</li>
                  <li dir="rtl">موقع الجهاز؛</li>
                  <li dir="rtl">عنوان&nbsp;IP؛</li>
                  <li dir="rtl">معلومات الجهاز، بما في ذلك نوع الجهاز المحمول الذي تستخدمه، ومعرف الجهاز الفريد&nbsp;على
                      سبيل المثال، رقم&nbsp;IMEI&nbsp;الخاص بجهازك، أو عنوان&nbsp;MAC&nbsp;لواجهة الشبكة اللاسلكية
                      للجهاز، أو التطبيقات المثبتة، أو رقم الهاتف المحمول الذي يستخدمه الجهاز.
                  </li>
                  <li dir="rtl">بيانات الرسائل القصيرة؛</li>
                  <li dir="rtl">المعلومات المتعلقة بالمعاملة؛</li>
                  <li dir="rtl">معلومات عن الحوادث المتعلقة بالسلامة؛</li>
                  <li dir="rtl">معلومات تاريخ التسجيل.</li>
                  <li dir="rtl"><strong>إذا كنت مستخدمًا نهائيًا، قد نقوم أيضًا بجمع</strong><strong>:</strong></li>
                  <li dir="rtl">تفاصيل الخدمة المحددة التي طلبتها؛</li>
                  <li dir="rtl">التعليقات التي تختار تقديمها للسائقين؛</li>
                  <li dir="rtl">موقعك المحدد في الوقت الذي تقوم فيه بالوصول إلى خدماتنا أو استخدامها؛</li>
                  <li dir="rtl">وقت وتاريخ حجزك للخدمة، إما في ذلك الوقت أو في وقت في المستقبل؛</li>
                  <li dir="rtl">وجهتك المحجوزة أو الفعلية؛</li>
                  <li dir="rtl">مسار رحلتك؛ و</li>
                  <li dir="rtl">البيانات الشخصية للأفراد الذين تقوم بالوصول إلى خدماتنا أو استخدامها نيابة عنهم، على
                      سبيل المثال، عندما تحجز رحلة للآخرين.
                  </li>
                  <li dir="rtl"><strong>إذا كنت سائقا، فإننا نجمع أيضًا</strong><strong>:</strong></li>
                  <li dir="rtl">أي تعليقات للمستخدم النهائي تختار تقديمها؛</li>
                  <li dir="rtl">تفاصيل أي رحلات قمت بها أو الخدمات التي قدمتها؛</li>
                  <li dir="rtl">مسار رحلتك؛</li>
                  <li dir="rtl">الوقت والتاريخ لأي حجوزات قمت بقبولها أو رفضها أو إلغائها.</li>
                  <li dir="rtl">نستخدم أيضًا تقنية نظام تحديد المواقع العالمي&nbsp;(GPS)&nbsp;لتحديد موقعك الحالي.
                      بالنسبة للسائق ، قد يتم استخدام تقنية نظام تحديد المواقع العالمي&nbsp;(GPS)&nbsp;عندما يكون تطبيق
                      جوول قيد التشغيل في الخلفية وفي المقدمة، سواء كان الكابتن متصلاً بالإنترنت أو غير متصل بالإنترنت.
                      بالنسبة للمستخدمين النهائيين، سيتم استخدام تقنية نظام تحديد المواقع العالمي&nbsp;(GPS)&nbsp;فقط
                      عندما يكون تطبيق جوول نشطًا في مقدمة تطبيقات هاتفك أو أثناء الرحلة.
                  </li>
                  <li dir="rtl">تتطلب بعض خدماتنا التي تدعم تحديد الموقع بياناتك الشخصية حتى تعمل الميزة. من خلال قبول
                      شروط إشعار الخصوصية هذا، فإنك توافق على استخدام تقنية نظام تحديد المواقع
                      العالمي&nbsp;(GPS)&nbsp;عندما يكون ذلك مناسبًا لاستخدام خدماتنا. يعد استخدام نظام تحديد المواقع
                      العالمي&nbsp;(GPS)&nbsp;إعدادًا يمكنك تشغيله وإيقاف تشغيله من إعدادات جهاز الهاتف الخاص بك في أي
                      وقت.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>مشاركة البيانات الشخصية</strong></li>
              </ol>
              <p dir="rtl">اعتمادًا على الأغراض التي جمعنا من أجلها بياناتك الشخصية، قد نشارك بياناتك الشخصية:</p>
              <ul>
                  <li dir="rtl"><strong>المستخدمون النهائيون&nbsp;</strong>:</li>
                  <li dir="rtl">يجوز لنا مشاركة بياناتك الشخصية:</li>
                  <li dir="rtl">مع شركاء جوول؛</li>
                  <li dir="rtl">مع الأطراف الثالثة التي نستخدمها لتنفيذ العمل نيابة عنا أو لتنفيذ عقد نبرمه معهم، بما في
                      ذلك:
                  </li>
                  <li dir="rtl"><strong>تقنيات جوول فيما يتعلق بـ</strong><strong>:</strong></li>
                  <li dir="rtl">بيانات الحساب التي يتم استخدامها عند التسجيل في منصة جوول لاستخدام خدمات رحلات جوول
                      والمستخدمة لتوفير تجربة سلسة عند التنقل بين الخدمات على منصة جوول، مثل هوية حسابك وطرق الدفع
                      والتفضيلات، و؛
                  </li>
                  <li dir="rtl"><strong>بيانات رحلات جوول</strong>،</li>
                  <li dir="rtl">(أ) تحسين موثوقية منصة جوول؛</li>
                  <li dir="rtl">(ب) تحسين تجربة دعم العملاء؛&nbsp;</li>
                  <li dir="rtl">(ج) تدريب النماذج؛&nbsp;</li>
                  <li dir="rtl">(د) تحديد الأنشطة الاحتيالية وغير الآمنة ومكافحتها؛&nbsp;</li>
                  <li dir="rtl">(هـ) إنشاء ملفات تعريف للمستخدمين لتسهيل تجربة موحدة على منصة جوول وتقديم عروض وعروض
                      ترويجية مخصصة.
                  </li>
                  <li dir="rtl">جوجل، فيما يتعلق باستخدام خرائط جوجل/جوجل إرث في منصة جوول (راجع سياسة الخصوصية الخاصة
                      بجوجل وشروط الخدمة الإضافية لخرائط جوجل/جوجل إرث للحصول على معلومات حول جمعها واستخدامها للبيانات
                      على :
                  </li>
                  <li dir="rtl"><a
                      href="https://www.google.com/policies/privacy">https://www.google.com/policies/privacy</a></li>
                  <li dir="rtl"><a
                      href="https://maps.google.com/help/terms_maps.html">https://maps.google.com/help/terms_maps.html</a>
                  </li>
                  <li dir="rtl"><strong>مقدمي خدمات التخزين السحابي؛</strong></li>
              </ul>
              <ol>
                  <ul>
                      <li dir="rtl">معالجات الدفع والوسطاء؛</li>
                      <li dir="rtl">شركاء التسويق ومقدمو منصات التسويق، بما في ذلك خدمات الإعلان على وسائل التواصل
                          الاجتماعي، وشبكات الإعلان، ومقدمو البيانات التابعون لجهات خارجية، ومقدمو الخدمات الآخرون الذين
                          يساعدوننا في فهمك بشكل أفضل كمستخدم وقياس فعالية الإعلان؛
                      </li>
                      <li dir="rtl">شركاء البحث، بما في ذلك أولئك الذين يقومون بإجراء الاستطلاعات أو مشاريع البحث
                          بالشراكة مع&nbsp;Jooool app&nbsp;أو نيابة عن&nbsp;Jooool app؛
                      </li>
                      <li dir="rtl">البائعون الذين يساعدون رحلات جوول على تعزيز سلامة وأمان خدماتنا؛</li>
                      <li dir="rtl">المستشارين والمحامين والمحاسبين ومقدمي الخدمات المهنية الآخرين؛</li>
                      <li dir="rtl">المطارات، بما في ذلك عندما تطلب سلطات المطار ذلك كشرط للعمل على ممتلكات المطار؛</li>
                      <li dir="rtl">المؤسسات المالية، مثل البنوك التي نتعاون معها لتقديم منتجات مشتركة.</li>
                      <li dir="rtl">مع أطراف ثالثة لتسويق منتجاتهم أو خدماتهم لك إذا وافقت على تلقي التحديثات
                          الترويجية؛
                      </li>
                      <li dir="rtl">إذا قمنا بإخطارك بخلاف ذلك ووافقت على المشاركة؛</li>
                      <li dir="rtl">مع أطراف ثالثة في شكل مجمع و/أو مجهول الهوية لا يمكن استخدامه بشكل معقول لتحديد
                          هويتك؛
                      </li>
                      <li dir="rtl">مع مسؤولي إنفاذ القانون، والهيئات التنظيمية المالية، ووكالات مكافحة غسل الأموال،
                          والسلطات الحكومية، ومسؤولي الصحة العامة، والسلطات المختصة أو أطراف ثالثة أخرى: (أ) لفرض أو
                          تطبيق شروط الاستخدام الخاصة بنا؛ (ب) للتحقيق في الانتهاكات المحتملة لهذه الشروط أو القوانين
                          المعمول بها أو إذا اعتقدنا أن أفعالك تتعارض مع شروط الاستخدام الخاصة بنا؛ (ج) لحماية حقوق أو
                          ممتلكات أو سلامة جوول أو الآخرين؛ أو (د) إذا اعتقدنا أن الإفصاح يتوافق مع، أو مطلوب بموجب أي
                          قانون أو لائحة أو ترخيص تشغيل أو اتفاقية أو عملية قانونية أو طلبات حكومية، أو حيث يكون الإفصاح
                          مناسبًا بخلاف ذلك بسبب السلامة أو مخاوف مماثلة؛
                      </li>
                      <li dir="rtl">مع معالج المطالبات أو شركة التأمين لتقديم أي معلومات ضرورية (بما في ذلك معلومات
                          الاتصال الخاصة بك) في حالة وجود شكوى أو نزاع أو صراع، والذي قد يشمل حادثًا، يشملك وطرفًا آخر
                          وتكون هذه المعلومات أو البيانات ضرورية لحل الشكوى أو النزاع أو الصراع؛ و
                      </li>
                      <li dir="rtl">مع أطراف ثالثة فيما يتعلق أو أثناء المفاوضات بشأن أي اندماج أو بيع لأصول الشركة أو
                          توحيدها أو إعادة هيكلتها أو تمويلها أو الاستحواذ على كل أو جزء من أعمالنا من قبل شركة أخرى أو
                          إليها.
                      </li>
                  </ul>
              </ol>
              <ul>
                  <li dir="rtl"><strong>للمستخدمين النهائيين فقط</strong> :</li>
                  <li dir="rtl">يجوز لنا مشاركة بياناتك الشخصية:</li>
                  <li dir="rtl">حسب طلبك، مثل عندما توافق على مشاركة وقت الوصول المتوقع وموقعك مع صديق؛</li>
                  <li dir="rtl">مع السائقين، لتمكينهم من تقديم الخدمات التي تطلبها. على سبيل المثال، نشارك اسمك ومواقع
                      الاستلام و/أو التسليم مع السائق؛
                  </li>
                  <li dir="rtl">مع أطراف ثالثة تختار أن تسمح لنا بمشاركة بياناتك الشخصية معها، بما في ذلك التطبيقات أو
                      مواقع الويب الأخرى التي تتكامل مع واجهة برمجة التطبيقات أو الخدمات الخاصة بنا، أو تلك التي تحتوي
                      على واجهة برمجة تطبيقات أو خدمة نتكامل معها؛
                  </li>
                  <li dir="rtl">مع صاحب العمل الخاص بك (أو كيان مماثل) إذا كان صاحب العمل الخاص بك يشارك في أي من حلولنا
                      المؤسسية/ الشركاتية.
                  </li>
                  <li dir="rtl"><strong>السائقين فقط</strong> :</li>
                  <li dir="rtl">مع المستخدمين النهائيين، لتمكينهم من التعرف عليك وعلى سيارتك؛</li>
                  <li dir="rtl">مع صاحب العمل الخاص بك أو شركة النقل التابعة لجهة خارجية التي يرتبط بها حساب الكابتن
                      الخاص بك؛ و
                  </li>
                  <li dir="rtl">مع شركات التأمين أو مقدمي حلول تمويل الأسطول الذين قد نعتبر أن عروض منتجاتهم مثيرة
                      للاهتمام بالنسبة لك.
                  </li>
                  <li dir="rtl"><strong>اتخاذ القرارات بشكل آلي</strong></li>
                  <li dir="rtl">نحن نستخدم البيانات الشخصية لاتخاذ قرارات آلية تتعلق باستخدام خدماتنا، مثل:</li>
              </ul>
              <ol>
                  <ol>
                      <ol>
                          <ol>
                              <li dir="rtl">تحديد تقييمات السائقين والمستخدمين النهائيين، وإلغاء تنشيط التقييمات
                                  المنخفضة؛
                              </li>
                              <li dir="rtl">مطابقة السائقين المتاحين مع المستخدمين النهائيين الذين يطلبون الخدمات بناءً
                                  على التوافر والقرب وعوامل أخرى؛
                              </li>
                              <li dir="rtl">تمكين التسعير الديناميكي، حيث يتم تحديد سعر الرحلة بناءً على عوامل متغيرة
                                  باستمرار مثل الوقت والمسافة المقدرين، والمسار المتوقع، وحركة المرور المقدرة، وعدد
                                  السائقين والمستخدمين النهائيين الذين يستخدمون جوول في لحظة معينة؛
                              </li>
                              <li dir="rtl">السائقين أو المستخدمين النهائيين الذين تم تحديد تورطهم في عمليات احتيال أو
                                  أنشطة غير آمنة أو أنشطة أخرى قد تضر برحلات جوول و/أو الآخرين.
                              </li>
                              <li dir="rtl">تقوم جوول بتنفيذ الأنشطة المذكورة أعلاه على أساس أنها ضرورية للوفاء
                                  بالتزاماتنا تجاه المستخدمين النهائيين بموجب شروط الاستخدام الخاصة بنا أو أي اتفاقيات
                                  أخرى مع المستخدمين، أو على أساس أنها ضرورية لأغراض المصالح المشروعة لشركة جوول
                                  ومستخدميها والآخرين.
                              </li>
                          </ol>
                      </ol>
                  </ol>
                  <li dir="rtl"><strong>النقل الدولي للبيانات الشخصية :</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">نظرًا للنطاق الإقليمي لأعمالنا، فإننا نعالج البيانات الشخصية على مستوى العالم. وهذا يعني
                      أنه يجوز لنا أيضًا نقل البيانات الشخصية إلى دول أخرى غير تلك التي تعيش فيها أو تستخدم فيها خدمات
                      جوول. عندما ننقل بياناتك الشخصية إلى الخارج، سنتخذ التدابير المناسبة لحماية بياناتك الشخصية وفقًا
                      لإشعار الخصوصية هذا وقوانين الدولة التي يتم النقل منها.
                  </li>
                  <li dir="rtl">عندما تتطلب القوانين المعمول بها موافقتك على معالجة البيانات الشخصية خارج البلد الذي
                      يمكنك الوصول فيه إلى الخدمات من خلال&nbsp;Jooool&nbsp;، فإنك تقدم هذه الموافقة لنا من خلال
                      الاستمرار في حجز الرحلة إلى أقصى حد ممكن بموجب القوانين المعمول بها.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>تخزين البيانات الشخصية</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">سوف نقوم بتخزين بياناتك الشخصية والاحتفاظ بها طوال الفترة الزمنية اللازمة للغرض الذي تم
                      جمع البيانات من أجله، ما لم تتغير طبيعة الغرض، وفي هذه الحالة قد يتم تمديد فترة الاحتفاظ أو
                      تقصيرها.
                  </li>
                  <li dir="rtl">بالإضافة إلى الأغراض التي نجمع من أجلها بياناتك الشخصية، فإننا نقوم أيضًا بتخزين هذه
                      البيانات الشخصية والاحتفاظ بها للحفاظ على سلامة قواعد بياناتنا، ولإجراء خدمات مستمرة نيابة عنك،
                      ولأغراض البحث والتحليلات والإحصائيات، ولضمان الامتثال للقوانين واللوائح المعمول بها.
                  </li>
                  <li dir="rtl">بعد تقديم طلب حذف الحساب (حيثما كان ذلك مضمونًا وفقًا لحقوق صاحب البيانات المشار إليها
                      أدناه)، سنقوم بمسح أو أرشفة البيانات الشخصية للفرد الذي يتعلق به المتطلب أو الطلب من الاستخدام
                      النشط، ما لم نكن ملزمين بالاحتفاظ بها بسبب المتطلبات القانونية أو التنظيمية، لأغراض السلامة
                      والأمان ومنع الاحتيال، أو بسبب مشكلة تتعلق بحسابك مثل الائتمان المستحق أو المطالبة أو النزاع غير
                      المحلول.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>اختياراتك</strong></li>
              </ol>
              <p dir="rtl"><strong>أذونات الجهاز</strong> :</p>
              <ul>
                  <li dir="rtl">حددت معظم منصات الأجهزة المحمولة iOS&nbsp;وAndroid&nbsp;وما إلى ذلك)&nbsp;أنواعًا معينة
                      من بيانات الجهاز التي لا يمكن للتطبيقات الوصول إليها دون إذن مالك الجهاز. وتختلف طرق الحصول على
                      هذا الإذن بين المنصات المختلفة. يُرجى التحقق من الإعدادات المتاحة على جهازك أو مراجعة مزود الخدمة
                      لديك.
                  </li>
                  <li dir="rtl"><strong>التسويق والانسحاب :</strong></li>
                  <li dir="rtl">في بعض البلدان، لا يمكننا أن نقدم لك أي اتصالات تسويقية مباشرة إلا بعد الحصول على
                      موافقتك الصريحة المسبقة للقيام بذلك.
                  </li>
                  <li dir="rtl">في جميع الحالات، توفر&nbsp;Jooool app&nbsp;للمستخدمين النهائيين والسائقين فرصة إلغاء
                      الاشتراك في تلقي اتصالات التسويق المباشرة منا ومن شركائنا:
                  </li>
                  <li dir="rtl">في أي اتصال تسويقي مباشر عبر البريد الإلكتروني تتلقاه منا؛ و</li>
                  <li dir="rtl">بالنسبة للمستخدمين النهائيين، من قائمة &quot;الإعدادات&quot; في تطبيق جوول.</li>
                  <li dir="rtl"><strong>حقوق صاحب البيانات</strong> :</li>
                  <li dir="rtl">إذا كنت ترغب في أن تقوم&nbsp;Jooool&nbsp;بتصحيح البيانات الشخصية التي تحتفظ
                      بها&nbsp;جوول عنك، يرجى تعديل البيانات من صحة الحساب الخاص بك بشكل مباشر.
                  </li>
                  <li dir="rtl">إذا قررت إلغاء تنشيط حسابك، يمكنك التواصل معنا&nbsp;</li>
                  <li dir="rtl">اعتمادًا على البلد الذي تتواجد فيه في الوقت الذي نجمع فيه بياناتك الشخصية ونستخدمها
                      لتزويدك بالخدمات، قد تتمتع ببعض أو كل الحقوق التالية بموجب القانون المعمول به:
                  </li>
                  <li dir="rtl">لطلب الوصول إلى بياناتك، بما في ذلك بيانات ملفك الشخصي وسجل الرحلات (المعروف
                      باسم &quot;طلب الوصول إلى بيانات صاحب البيانات&quot;). إذا كانت المعلومات التي تطلبها تكشف عن
                      بيانات شخصية عن طرف ثالث، فسنقوم بما يلي:
                  </li>
                  <li dir="rtl">طلب موافقة هذا الفرد قبل الرد على طلبك،</li>
                  <li dir="rtl">النظر فيما إذا كان من المعقول تقديمها لك؛</li>
                  <li dir="rtl">(ج) سنقوم بتحرير البيانات الشخصية لأطراف ثالثة قبل الرد. إذا لم نتمكن من منحك إمكانية
                      الوصول إلى بياناتك الشخصية لأن الكشف عنها من شأنه أن ينتهك حقوق وحريات أطراف ثالثة، فسنقوم بإخطارك
                      بهذا القرار.
                  </li>
                  <li dir="rtl">لطلب محو بياناتك الشخصية وإلغاء حسابك من جوول في الحالات التالية:</li>
                  <li dir="rtl">لم تعد هناك حاجة لبياناتك الشخصية للأغراض التي تم جمعها من أجلها؛</li>
                  <li dir="rtl">كان الأساس القانوني الوحيد للمعالجة هو موافقتك وقد سحبتها؛</li>
                  <li dir="rtl">لقد اعترضت على معالجة بياناتك الشخصية على أسس قانونية تتعلق بحالتك الخاصة، ولا يمكن
                      لشركة جوول إثبات وجود مصالح مشروعة ملزمة للمعالجة؛
                  </li>
                  <li dir="rtl">تمت معالجة بياناتك الشخصية بشكل غير قانوني؛ أو</li>
                  <li dir="rtl">يجب مسح بياناتك الشخصية للامتثال للمتطلبات القانونية.</li>
                  <li dir="rtl">قبل طلب حذف حسابك، عليك أن تفهم أنك ستخضع أيضًا للشروط والأحكام الخاصة بشركة&nbsp;Jooool
                      apps&nbsp;والتي قد تشمل على سبيل المثال لا الحصر إكمال/إلغاء أي حجوزات نشطة أو مجدولة، وإلغاء
                      الاشتراكات وإفراغ محفظة&nbsp;jooool PAY&nbsp;الخاصة بك. إذا كنت المستخدم النهائي لحساب مؤسسي/شركة،
                      فقد لا نتمكن من معالجة طلب الحذف الخاص بك إذا كانت هناك مدفوعات مستحقة على حساب المؤسسة هذا.
                  </li>
                  <li dir="rtl">بعد أن نتلقى طلب الحذف الخاص بك، سيتم تسجيل خروجك من حساب جوول الخاص بك. وسنقوم بمعالجة
                      طلبك خلال 90 يومًا. إذا قمت بتسجيل الدخول إلى حساب جوول الخاص بك خلال هذه الأيام التسعين، فسيتم
                      إلغاء حساب الحذف الخاص بك وسيظل حسابك دون تغيير.
                  </li>
                  <li dir="rtl">قد يُطلب من جوول الاحتفاظ ببعض البيانات بما يتماشى مع المتطلبات القانونية أو التنظيمية،
                      لأغراض السلامة والأمان ومنع الاحتيال، أو بسبب مشكلة تتعلق بحسابك مثل رصيد مستحق أو مطالبة أو نزاع
                      لم يتم حله.
                  </li>
                  <li dir="rtl">الاعتراض على معالجتنا لبياناتك الشخصية حيث تعتمد&nbsp;Jooool app&nbsp;على مصلحة مشروعة
                      (أو مصالح طرف ثالث) وهناك شيء ما في حالتك الخاصة يجعلك ترغب في الاعتراض على المعالجة على هذا
                      الأساس.
                  </li>
                  <li dir="rtl">لطلب تقييد معالجتنا لبياناتك الشخصية عندما:</li>
                  <li dir="rtl">تعتقد أن معالجتنا لبياناتك الشخصية غير دقيقة؛</li>
                  <li dir="rtl">تعتبر أن المعالجة غير قانونية وتعارض محو بياناتك الشخصية وتطلب تقييد المعالجة بدلاً من
                      ذلك؛
                  </li>
                  <li dir="rtl">لم نعد بحاجة إلى البيانات الشخصية لأغراض المعالجة ولكنك تحتاج إلى البيانات الشخصية
                      لإقامة دعوى قانونية أو ممارستها أو الدفاع عنها؛ أو
                  </li>
                  <li dir="rtl">حيث تتطلب التحقق من أن المعالجة على أساس المصالح المشروعة لجوول تتجاوز مصالحك.</li>
                  <li dir="rtl">إلغاء موافقتك، حيث طلبنا موافقتك صراحةً كأساس قانوني لمعالجة بياناتك الشخصية. لن يؤثر
                      إلغاء موافقتك على قانونية المعالجة بناءً على موافقتك المسبقة.
                  </li>
                  <li dir="rtl">اعتمادًا على موقعك أو إصدار تطبيق&nbsp;جوول الذي تستخدمه، يمكنك ممارسة هذه الحقوق من
                      خلال قائمة الإعدادات في تطبيق&nbsp;Jooool app&nbsp;أو عن طريق الاتصال بـ&nbsp;Jooool
                      app&nbsp; &nbsp;.
                  </li>
                  <li dir="rtl">قد نتواصل معك للحصول على معلومات إضافية إذا لم نتمكن من التحقق من هويتك، أو كان نطاق
                      طلبك غير واضح أو لم يقدم معلومات كافية لنا لإجراء بحث (على سبيل المثال، ستكون هذه هي الحالة إذا
                      طلبت &quot;كل المعلومات عني&quot;). بمجرد حصولنا على كل المعلومات، نحتاج إلى تحديد موقع بياناتك
                      الشخصية، وسنبدأ في معالجة طلبك.
                  </li>
                  <li dir="rtl">قد يسمح لنا القانون المعمول به أو يتطلب منا رفض التصرف بناءً على طلبك، أو قد نقوم بتدمير
                      أو مسح أو إخفاء بياناتك الشخصية وفقًا لالتزاماتنا وممارساتنا المتعلقة بالاحتفاظ بالسجلات. إذا
                      قررنا أن طلبك لا يستند إلى أساس قانوني، فإننا نحتفظ بالحق في عدم معالجة طلبك وسنبلغك بهذا القرار.
                  </li>
              </ul>
              <p dir="rtl">بالإضافة إلى ذلك، واعتمادًا على موقعك، قد يكون لديك الحق في تقديم شكوى تتعلق بمعالجتنا
                  لبياناتك الشخصية إلى هيئة حماية البيانات في بلدك.</p>
              <ol>
                  <li dir="rtl"><strong>الاحتياطات الأمنية</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">ملفك الشخصي على&nbsp;Jooool &nbsp;&nbsp;محمي بكلمة مرور بحيث لا يتمكن أحد من الوصول إلى
                      معلومات حسابك سوى أنت
                      وJooool&nbsp;وموظفيها <strong>&quot; المعتمدون</strong> &quot; &quot;)&nbsp;ولن يتواصل معك
                      موظفو&nbsp;المعتمدون بشكل استباقي ويطلبوا منك أي معلومات شخصية عن حسابك، بما في ذلك كلمة المرور
                      الخاصة بك.
                  </li>
                  <li dir="rtl">تبذل&nbsp;Jooool &nbsp;&nbsp;قصارى جهدها لضمان أمان بياناتك الشخصية.
                      لدى&nbsp;Jooool &nbsp;&nbsp;زملاء مكرسون للحفاظ على الالتزام بإشعار الخصوصية هذا ومبادرات الخصوصية
                      الأخرى، ومراجعة أمان خدماتنا بشكل دوري والتأكد من أن جميع زملاء&nbsp;Jooool &nbsp;&nbsp;على دراية
                      بممارساتنا الأمنية. لسوء الحظ، لا يمكن ضمان أمان أي نقل بيانات عبر الإنترنت بنسبة 100%. ونتيجة
                      لذلك، لا يمكن لـ&nbsp;Jooool &nbsp;&nbsp;ضمان أمان أي بيانات شخصية ترسلها إلينا، وتقوم بذلك على
                      مسؤوليتك الخاصة. إذا كانت لديك أي أسئلة أخرى حول هذه المسألة، فراجع شروط الاستخدام. إلى الحد الذي
                      يسمح به القانون المعمول به، تخلي&nbsp;Jooool &nbsp;&nbsp;صراحةً عن أي مسؤولية، وتتنازل بموجبه عن
                      أي مطالبات، وتعوضنا عن أي مطالبات قد يرفعها أطراف ثالثة تصل إلى خدماتنا لتأجير السيارات أو
                      تستخدمها عبر حسابك، والتي قد تنشأ في حالة حصول أي أفراد آخرين على البيانات الشخصية التي
                      تجمعها&nbsp;jooool&nbsp;عنك أو أطراف ثالثة تصل إلى خدماتنا أو تستخدمها عبر حسابك.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>ملفات تعريف الارتباط</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">ملفات تعريف الارتباط عبارة عن ملفات نصية صغيرة يتم تخزينها على المتصفحات أو الأجهزة
                      بواسطة مواقع الويب والتطبيقات والوسائط عبر الإنترنت والإعلانات. نستخدم ملفات تعريف الارتباط
                      والتقنيات المماثلة لأغراض مثل:
                  </li>
                  <li dir="rtl">تمييزك عن المستخدمين الآخرين على منصة جوول؛</li>
                  <li dir="rtl">تذكر تفضيلاتك وإعداداتك</li>
                  <li dir="rtl">فهم السلوكيات والاهتمامات عبر الإنترنت للأشخاص الذين يتفاعلون مع خدماتنا، بما في ذلك
                      تحديد شعبية المحتوى وتحليل حركة المرور على الموقع والاتجاهات
                  </li>
                  <li dir="rtl">تحسين الخدمات التي نقدمها</li>
                  <li dir="rtl">يجوز لنا أيضًا السماح للآخرين بتقديم خدمات قياس الجمهور والتحليلات نيابةً عنا، وتقديم
                      الإعلانات نيابةً عنا عبر الإنترنت، وتتبع أداء تلك الإعلانات وإعداد التقارير عنها. يجوز لهذه
                      الكيانات استخدام ملفات تعريف الارتباط ومنارات الويب ومجموعات تطوير البرامج والتقنيات الأخرى لتحديد
                      الأجهزة التي يستخدمها زوار مواقعنا الإلكترونية، وكذلك عند زيارتهم لمواقع وخدمات أخرى عبر الإنترنت.
                  </li>
                  <li dir="rtl"><strong>أنت توافق</strong>:<br/>على أن جوول / jooool &nbsp; &nbsp;&nbsp;قد تستخدم
                      البيانات الشخصية التي تقدمها &nbsp;&nbsp;لأغراض اتصال&nbsp;Jooool&nbsp; &nbsp; أو&nbsp;بك مباشرة
                      لتسويق خدماتهما المنفصلة لك
                  </li>
                  <li dir="rtl"><strong>المستخدمون الضيوف</strong></li>
                  <li dir="rtl">نعتبرك مستخدمًا ضيفًا إذا كنت تتلقى رحلات ولكنك لست مالكًا لحساب
                      في&nbsp;Jooool &nbsp;&nbsp;، على سبيل المثال عندما:
                  </li>
                  <li dir="rtl">يمكنك طلب رحلة عبر مواقع الويب الشريكة أو التطبيقات مثل شركات تجميع سيارات الأجرة.</li>
                  <li dir="rtl">تم ترتيب الرحلة من قبل مالك حساب آخر.</li>
                  <li dir="rtl">قد تكون البيانات الشخصية لمستخدم الضيف مرتبطة بمالك حساب جوول ويمكنه الوصول إليها.</li>
                  <li dir="rtl">في هذه الحالات، يتم استخدام بياناتك الشخصية وقد تتم مشاركتها مع أطراف ثالثة:</li>
                  <li dir="rtl">لتوفير مثل هذه الرحلات؛</li>
                  <li dir="rtl">(ب) لأغراض السلامة والأمان، ودعم العملاء، والبحث والتطوير، وتمكين الاتصال بين
                      المستخدمين، وفيما يتصل بالإجراءات والمتطلبات القانونية.
                  </li>
                  <li dir="rtl"><strong>الموردين</strong></li>
                  <li dir="rtl">نحن نعتبرك موردًا إذا كنت فردًا (وليس سائقا) يقدم خدمات إلى جوول، إما بصفته الخاصة، أو
                      نيابة عن منظمة كعضو أو موظف في تلك المنظمة.
                  </li>
                  <li dir="rtl">إذا كنت موردًا أو تعمل لدى أحد الموردين، فإننا نجمع البيانات الشخصية عندما تقوم أنت أو
                      الشركة التي تمثلها بتقديم خدمات لنا أو تسعى إلى تقديمها لنا؛ أو التواصل معنا بأي طريقة أخرى.
                  </li>
                  <li dir="rtl">قد تتضمن البيانات الشخصية التي تم جمعها أثناء ذلك ما يلي:</li>
                  <li dir="rtl">اسمك؛</li>
                  <li dir="rtl">عنوان بريدك الإلكتروني؛</li>
                  <li dir="rtl">رقم تليفونك؛</li>
                  <li dir="rtl">عنوانك البريدي؛</li>
                  <li dir="rtl">رقم الهوية الوطنية؛</li>
                  <li dir="rtl">معلومات البنك الذي سيتم إجراء الدفع مقابل الخدمات عليه؛</li>
                  <li dir="rtl">يجوز لنا استخدام البيانات الشخصية التي تم جمعها بالطرق الموضحة أعلاه من أجل:</li>
                  <li dir="rtl">للاتصال بك فيما يتعلق بتقديم خدماتك لنا؛ و</li>
                  <li dir="rtl">لتسهيل الدفع لك؛ و</li>
                  <li dir="rtl">لدراسة علاقتنا المحتملة أو الحالية مع عملك.</li>
                  <li dir="rtl">إذا كنت أحد الموردين، فسنعتمد على أطراف ثالثة تساعدنا في التحقق من هويتك ومعلوماتك
                      الأساسية لأغراض قانونية وتنظيمية ومكافحة غسيل الأموال والعقوبات والأمن. وقد يشمل ذلك الفحص وفقًا
                      لقوائم العقوبات وقوائم الأشخاص المعرضين سياسيًا والأقارب/المقربين من هؤلاء الأشخاص ووسائل الإعلام
                      المعادية.
                  </li>
                  <li dir="rtl">يرجى ملاحظة أنه إذا اخترت عدم تزويدنا ببعض هذه البيانات الشخصية، أو طلبت منا مسح بعضها
                      أو كلها، فقد لا نتمكن من إشراكك أو الاستمرار في إشراكك في تقديم الخدمات .
                  </li>
                  <li dir="rtl"><strong>الزوار</strong> :</li>
                  <li dir="rtl">نعتبرك زائرًا إذا قمت بزيارة مقر جوول بخلاف كونك موظفًا أو متعاقدًا فرديًا مع شركة جوول.
                      عند زيارة مقر جوول، قد يُطلب منك إدخال معلومات شخصية والتسجيل كزائر باستخدام نظام إدارة الزوار.
                      أثناء هذا التسجيل، قد يُطلب منك أيضًا التوقيع على اتفاقية عدم إفشاء.
                  </li>
                  <li dir="rtl">يتم استخدام هذه المعلومات لضمان سلامة الأفراد داخل مقر جوول وحماية المعلومات التي تتم
                      مشاركتها معك أثناء زيارتك لمقر جوول.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>التغييرات على إشعار الخصوصية هذا :</strong></li>
              </ol>
              <ul>
                  <li dir="rtl">يجوز لنا تغيير إشعار الخصوصية هذا من وقت لآخر. إذا أجرينا تغييرات كبيرة في الطريقة التي
                      نتعامل بها مع بياناتك الشخصية، أو في إشعار الخصوصية، فسنقوم بإخطارك عبر الخدمات أو من خلال بعض
                      الوسائل الأخرى، مثل البريد الإلكتروني أو الإشعار الفوري.
                  </li>
                  <li dir="rtl">إلى الحد الذي يسمح به القانون المعمول به، فإن استمرارك في استخدام الخدمات بعد هذا
                      الإشعار يشكل موافقتك على التغييرات إلى الحد الذي يسمح به القانون.
                  </li>
                  <li dir="rtl">نحن نشجعك على مراجعة إشعار الخصوصية هذا بشكل دوري للحصول على أحدث المعلومات حول ممارسات
                      الخصوصية لدينا.
                  </li>
              </ul>
              <ol>
                  <li dir="rtl"><strong>اتصل بنا</strong> :</li>
              </ol>
              <p dir="rtl">إذا كانت لديك أي أسئلة أو تعليقات أو طلبات تتعلق بإشعار الخصوصية هذا، أو إذا كانت لديك أي
                  أسئلة حول كيفية معالجة جوول لبياناتك الشخصية، فيرجى الاتصال بنا عن طريق البريد الإلكتروني : <a
                      href="mailto:cokmkcokm@gmail.com">cokmkcokm@gmail.com</a> .</p>
          </div>

      </div>
        <Footer/>
    </div>
  );
}
